*
{
    margin: 0;
    padding: 0;
}


/* General */
html,
body
{
    overflow: hidden;
    font-family: 'Fira Sans', sans-serif;
    cursor: auto;
}


img{
    z-index: 10;
    width: 50px;
}

u{
    text-decoration-line: none ;
    text-underline-offset: 2px;
    color: #bee1e6;

}
a{
    text-underline-offset: 2px;
    color: #bee1e6; 
}

i{
    font-size: 2rem;
    color: white; 

}

.icons{
    margin-top: 2.5rem;
    background: #003153;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
/* Loading */
.loading-bar{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #003153;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
    will-change: transform;
}

.webgl
{
    z-index: -10;
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.displayNone{
    display: none;
}

/* Cursor custum */

/* .cursor{
z-index: 30;

position: fixed ;
width: 50px;
height: 50px;
border: 1px solid whitesmoke;
border-radius: 50%;
left: 0;
top: 0;
pointer-events: none;
transform: translate(-50%, -50%);
transition: .1s

}
.cursor2{
z-index: 30;
background: #003053;
position: fixed ;
width: 8px;
height: 8px;
border: 1px solid whitesmoke;
border-radius: 50%;
left: 0;
top: 0;
pointer-events: none;
transform: translate(-50%, -50%);
transition: .15s
} */

/* Logo */ 

.logo {
    opacity: .8;
    width: 30px;
    position: absolute;
    top:20px;
    left:10px;
}

/* Compteur*/

/* .vertical{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30vh;
    left: 10px;

}
.compteur{
    font-family: 'Fira Sans', sans-serif;
    opacity: .4;
    color: white;
    writing-mode: vertical-lr;
    text-orientation: sideways-right;
    font-size: .8em;
    letter-spacing: .4em;

} */


/* Starter */

.starter{
    align-items: center;
    overflow: auto;
    z-index: 20;
    background: rgba(255, 255, 255);
    position: absolute;
    width: 95vw;
    height: 95vh;
    max-width: 800px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


}
.starter::-webkit-scrollbar {
    display: none;
}
.starterText{
    font-weight: 200;
    /* min-height: 60vh; */
    background: #003053;
    font-size: 3.5rem;
    color: white;
    padding: 3rem;
    text-align: left;
    margin-top: 1rem;

    
}
.starterOk{
    margin-top: 2rem;
    margin-bottom: 2rem;
    background: rgba(240, 255, 240, 0.096);
    font-size: 2rem;
    color: #003053;
    /* padding: 1rem; */
    text-align: center;
    
}

.starterOk:hover{
    color: #003053a6;
}
.starterNo{
    font-size: .8rem;
    color: grey;
    padding: 2rem;
    text-align: center;

    
}

/* Swiper */

.swiper-container {
    cursor: grab;

    width: 85vw;
    height: 300px;
}

.swiper-slide {

    max-width: 400px;
    background: #003053;
    /* margin-right: 10px; */
}

.swiper-slide img{
    width: 100%;
}

/* Popup */
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


.popup{
    overflow: auto;
    opacity: 1;
    z-index: 19;
    position: absolute;
    background: #003153;
    width: 85vw;
    max-width: 800px;

    min-height: 85vh;
    height: 85vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadein .8s;
    
}
.popup::-webkit-scrollbar {
    display: none;
}
.popupClose{
    margin: 5px;
    font-size: 2rem;
    color: white;
    cursor: grab;

}
.popupClose:hover{
    color: rgb(167, 167, 167)
}

.navigation{
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    justify-content: space-between;
    align-items: center;
    background: #003153;
    padding: 1rem;
    /* border: 2px solid #003053; */
    top: 0
}

.titre{
    color: white;
    font-size: 3rem;
}

.titreContainer{
    background: white;
    color: #003153;
    padding: 2rem;
    font-size: 3rem;
    text-transform: uppercase;
}

.whiteParagraphe{

    overflow: auto;
    background: white;
    color: #003153;
    padding: 2rem;
    font-size: 2rem;
    font-weight: 200
}
.blueParagraphe{
    max-width: 50ch;

    overflow: auto;
    background: #003153;
    color:   white;
    padding: 2rem;
    font-size: 2rem;
    font-weight: 200

}


/* Dividers */

.divider{
    width: max-content;
    height: 10px;
    background: blanchedalmond;
}


/* Btn */
/* 
.btnR{
    background: red;
    position: fixed;
    top: 80%;
    right: 20%;
}
.btnL{
    background: red;
    position: fixed;
    top: 80%;
    left: 20%;
} */



/* Points */
/* .point{
    position: absolute;
    top: 50%;
    left: 50%; 
}

.point:hover .text{
    opacity: 1;


}

.point.visible .label{
    transform: scale(1, 1);
}


.point .label{
    position: absolute;
    width: 40px;
    height: 40px;
    top: -20px;
    left: -20px;
    border-radius: 50%;
    background: rgba(245, 245, 245, 0.459);
    color: #003153;
    text-align: center;
    line-height: 40px;
    cursor: help;
    transform: scale(0, 0);
    transition: transform 0.3s;
}


.point .text{
    opacity: 0;
    position: absolute;
    pointer-events: none;
    top: 30px;
    text-align: center;
    left: -100px;
    width: 200px;
    background: rgba(245, 245, 245, 0.459);
    line-height: 1.3em;
    padding: .5em;
    border-radius: 4px;
    transition: opacity 0.3s;
    will-change: opacity;

} */

.footer{
    /* background:chartreuse ; */
    z-index: 5;
    width: 100vw;
    position: absolute;
    bottom: 10px;
    /* background: rgba(240, 255, 240, 0.096); */

}

@keyframes opacityContact {
    0%  { opacity: .3; }
    50%  { opacity: .7; }
    100% { opacity: .3; }

}
.contact{
    display: block;
    align-items: baseline;
    justify-content: center;
    padding: .5rem;
    text-align: center;
    font-family: 'Fira Sans', sans-serif;
    opacity: 1;
    color: white;
    font-size: .8em;
    letter-spacing: .4em;
    animation: opacityContact 5s infinite;
    
}
.h1Contact{
    color: white;
    padding-left: 2.8rem; 
    font-size: 2rem;
    font-weight: 200; 
    font-size: 1.5rem
    
}
.aContact{
    padding-top: 1rem;
    padding-left: 2.8rem; 
    color: #bee1e6;
    font-weight: 400; 
    font-size: 1.5rem
    
}
h4.contactObjet{
    margin-left: 10px;
    margin-right: 10px;
}


@media screen and (max-width: 420px) {
    .starterText{

        font-size: 1.9rem;
        padding: 2rem;

        
    }
    .blueParagraphe{
        font-size: 1.2rem;
    }
    .whiteParagraphe{
        font-size: 1.2rem;
    }
    .aContact{
        font-size: 1rem;
    }
}